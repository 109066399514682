<template>
  <v-container fluid>
    <v-row>
      <v-col
        class="text-center mt-10"
        cols="12"
      >
        <img
          src="/img/assets/value.png"
          width="260"
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col
        class="text-center mt-4"
        cols="12"
      >
        <div v-show="state === 'play'">
          <div class="d-inline-flex text-no-wrap mt-12">
            <v-text-field
              v-model="code"
              v-mask="'NNNNN'"
              :disabled="loading"
              autofocus
              class="d-inline-flex input-uppercase"
              clearable
              flat
              height="63"
              hide-details
              light
              single-line
              solo
              style="width: 500px; max-width: 500px;"
            />

            <v-btn
              :disabled="loading"
              :loading="loading"
              class="d-inline-flex ml-2"
              color="brand-secondary"
              depressed
              height="63"
              @click="submitCode"
            >
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </div>

          <div class="mt-10">
            <img
              src="/img/assets/play.png"
              style="max-height: 160px;"
            >
          </div>
        </div>

        <div v-show="state === 'win' && gift">
          <img
            :src="`/img/assets/gift-${gift}.png`"
            style="max-height: 160px;"
          >

          <div class="mt-6">
            <v-btn
              color="brand-secondary"
              depressed
              height="63"
              @click="playGame"
            >
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </div>
        </div>

        <div v-show="state === 'loose'">
          <img
            src="/img/assets/thanks.png"
            style="max-height: 160px;"
          >

          <div class="mt-6">
            <v-btn
              color="brand-secondary"
              depressed
              height="63"
              @click="playGame"
            >
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import i18nRouteMeta from '@/mixins/i18n/i18nRouteMeta'
import API           from '@/api/Api'

export default {
  name      : 'Home',
  components: {},
  directives: {},
  mixins    : [i18nRouteMeta],
  props     : {},
  enums     : {},
  dataStore : {},
  data () {
    return {
      loading: false,
      state  : '',
      gift   : '',
      code   : ''
    }
  },
  computed: {
    isFormValid () {
      return this.code && this.code?.length === 5
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
    this.playGame()
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    submitCode () {
      if (!this.isFormValid) return

      this.loading = true
      const payload = this.code
      this.code = ''

      API.Resource.InstantWin.Spin(payload)
        .then(response => {
          if (API.isResponseSuccess(response)) {
            const data = API.responseData(response)
            this.state = 'win'
            this.gift = data?.title || ''
          } else {
            this.gift = ''
            this.state = 'loose'
          }
        })
        .catch(e => {
          this.gift = ''
          this.state = 'loose'
        })
        .finally(() => {
          this.loading = false
        })
    },

    playGame () {
      this.code = ''
      this.gift = ''
      this.state = 'play'
    }
  }
}
</script>

<style scoped>
/deep/ .input-uppercase input {
  text-transform : uppercase;
  font-size      : 32px;
}
</style>
