import Vue                                          from 'vue'
import Storage                                      from 'vue-web-storage'
import VueMeta                                      from 'vue-meta'
import App                                          from '@/App'
import vuetify                                      from '@/plugins/vuetify'
import { resetRouter, router }                      from '@/router'
import { i18n, initI18nLanguages, setI18nLanguage } from '@/lang/lang'
import DataStorePlugin                              from '@/lib/data/dataStore/plugin/DataStorePlugin'
import EventBus                                     from '@/lib/events/eventBus'
import Enums                                        from '@/lib/enums/plugin'
import { themeUtils }                               from '@/lib/utils'
import EnvMixin                                     from '@/mixins/env'
import AppConfig                                    from '@/mixins/appConfig'
import RouterReadyMiddleware                        from '@/router/middleware/RouterReadyMiddleware'
import RouteMiddleware                              from '@/lib/middleware/RouteMiddleware'
import LangMiddleware                               from '@/router/middleware/LangMiddleware'
import VueMask                                      from 'v-mask'
import './App.css'
import '@mdi/font/css/materialdesignicons.css'

require('@openfonts/roboto_greek')

const DefaultLayout = () => themeUtils.importThemeComponent('layouts/DefaultLayout')
// const DefaultLayout = import(/* webpackChunkName: "DefaultLayout" */ '@/theme/default/layouts/DefaultLayout')

Vue.use(Storage, {
  prefix : `${ process.env.VUE_APP_STORAGE_PREFIX }_`,
  drivers: ['session', 'local']
})

const initVue = () => {
  resetRouter()

  Vue.component('DefaultLayout', DefaultLayout)

  Vue.mixin(EnvMixin)
  Vue.mixin(AppConfig)

  Vue.use(VueMask)
  Vue.use(EventBus)
  Vue.use(DataStorePlugin)
  Vue.use(Enums)
  Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
  })

  window.Vue = new Vue({
    el        : '#app',
    directives: {},
    mixins    : [],
    data () {
      return {}
    },
    beforeMount () {
      setI18nLanguage()

      this.$router.onReady(RouterReadyMiddleware)

      router.beforeResolve(RouteMiddleware({
        LangMiddleware
      }, true, this))
    },
    beforeCreate () {
    },
    created () {
    },
    mounted () {
    },
    beforeDestroy () {
    },
    methods: {},
    i18n,
    router,
    vuetify,
    render : h => h(App)
  })

  if (process.env.NODE_ENV === 'production') {
    Vue.config.productionTip = false
    Vue.config.devtools = false
    Vue.config.debug = false
    Vue.config.silent = true
  }
}

const init = (data = {}) => {
  initI18nLanguages()
  initVue()
}

init()
