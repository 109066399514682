<template>
  <v-app>
    <app-toolbar
      :height="146"
      :logo="true"
    />

    <v-main>
      <v-container
        class="fill-height"
        fluid
        ma-0
        pa-0
      >
        <v-row class="fill-height">
          <v-col
            cols="12"
            lg="10"
            offset-lg="1"
          >
            <slot />
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <img
      src="/img/assets/ball.png"
      style="position: absolute; top: 55px; left: 63px; z-index: 6;"
    >

    <img
      src="/img/assets/balls.png"
      style="position: absolute; top: 210px; right: 35px; z-index: 6;"
    >
  </v-app>
</template>

<script>
import AppToolbar from '@/theme/default/components/app/AppToolbar'

export default {
  name      : 'DefaultLayout',
  components: {
    AppToolbar
  },
  mixins   : [],
  dataStore: {},
  data     : function () {
    return {}
  },
  computed: {},
  watch   : {},
  created () {
  },
  mounted () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  methods: {}
}

</script>

<style>

</style>
