<template>
  <v-app-bar
    :height="height"
    :style="$vuetify.breakpoint.lgAndUp ? 'padding-left: 8.3333333333%; padding-right: 8.3333333333%;' : ''"
    app
    clipped-right
    color="brand-secondary"
    style="box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.12);"
  >
    <div
      v-if="logo"
      class="text-center align-center justify-center fill-width"
    >
      <router-link
        :to="{name: 'Home'}"
        style="line-height: 0"
      >
        <img
          :alt="$t('Common.App.Title')"
          :src="'/img/logo/logo.png'"
          height="102"
        >
      </router-link>
    </div>
  </v-app-bar>
</template>

<script>

export default {
  name      : 'AppToolbar',
  components: {},
  directives: {},
  mixins    : [],
  props     : {
    logo: {
      type   : Boolean,
      default: true
    },
    height: {
      type   : [String, Number],
      default: 68
    }
  },
  dataStore: {},
  data () {
    return {}
  },
  computed: {
    title () {
      const key = `Route.${ this.$route.name }.Title`
      return this.$te(key) ? this.$t(key) : this.$t('Common.App.Title')
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {}
}
</script>

<style scoped>
/deep/ .v-toolbar__content {
  padding-top    : 0;
  padding-bottom : 0;
  height         : 100% !important;
}
</style>
