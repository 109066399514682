import { themeUtils } from '@/lib/utils'
import { i18n }       from '@/lang/lang'

// eslint-disable-next-line no-unused-vars
const PlaceHolder = () => themeUtils.importThemeComponent('components/common/PlaceHolder')

const Home = () => themeUtils.importThemeComponent('views/home/Home')

export const AppRoutes = {
  SimpleRoutes: [
    {
      name    : 'Default',
      path    : '*',
      redirect: () => {
        return '/' + i18n.locale
      }
    }
  ],

  LangRoutes: [
    {
      name     : 'Home',
      path     : '',
      component: Home,
      meta     : {
        requiresAuth: false,
        layout      : 'default'
      }
    }
  ]
}

export default AppRoutes
