export default {

  App: {
    Title: 'Grouthfund Instant Win',
    Desc : '',
    Img  : ''
  },

  Search: {
    Title: 'Αναζήτηση'
  },

  Units: [
    {
      Id      : 1,
      Name    : 'Τεμάχιο (Τμχ)',
      BaseUnit: 'Τμχ',
      RateUnit: 'Τμχ',
      Rate    : 1,
      Divisor : 1.000000
    },
    {
      Id      : 2,
      Name    : 'Κιλό (kg)',
      BaseUnit: 'kg',
      RateUnit: 'g',
      Rate    : 1000,
      Divisor : 0.001000
    },
    {
      Id      : 3,
      Name    : 'Λίτρο (L)',
      BaseUnit: 'L',
      RateUnit: 'ml',
      Rate    : 1000,
      Divisor : 0.001000
    }
  ],

  Message: {
    Loading: {
      Title   : 'Φόρτωση',
      Wait    : 'Παρακαλώ Περιμένετε',
      Progress: '%{progress}%'
    },

    Sort: {
      Title : 'Ταξινόμηση',
      SortBy: 'Ταξινόμηση με'
    },

    Group: {
      Title  : 'Ομαδοποίηση',
      GroupBy: 'Ομαδοποίηση ανά'
    },

    Filter: {
      Title   : 'Φιλτράρισμα',
      FilterBy: 'Φιλτράρισμα ανά κατηγορία'
    }
  },

  Confirm: {
    Delete: {
      Title   : 'Διαγραφή {Id}',
      SubTitle: 'Επιβεβαίωση Διαγραφής',
      Body    : 'Είστε σίγουροι ότι θέλετε προχωρήσετε σε οριστική διαγραφή;',
      Validate: {
        Text  : 'Για διαγραφή, πληκτρολογήστε <span class="font-weight-regular error--text">@:Common.Confirm.Delete.Validate.Answer</span> στο παρακάτω πλαίσιο κειμένου.',
        Answer: 'sudo delete'
      }
    }
  },

  Date: {
    Title     : 'Ημερομηνία | Ημερομηνίες',
    Today     : 'Σήμερα',
    Day       : 'Ημέρα | Ημέρες',
    Week      : 'Εβδομάδα | Εβδομάδες',
    Month     : 'Μήνας | Μήνες',
    Year      : 'Έτος | Έτη',
    Period    : 'Περίοδος | Περίοδοι',
    PeriodFrom: 'Από',
    PeriodTo  : 'Έως',
    Expire    : 'Λήξη'
  },

  CookieLaw: {
    Message     : 'Η ιστοσελίδα χρησιμοποιεί cookies για βελτιστοποίηση της εμπειρίας του χρήστη. Με τη χρήση αυτού του ιστοτόπου, αποδέχεστε τη χρήση των cookies. Για περισσότερες πληροφορίες δείτε την',
    AcceptButton: 'Αποδοχή'
  },

  SelectQrItemDialog: {
    Title   : 'Τραπέζια',
    Subtitle: 'Επιλέξτε τραπέζι'
  },

  QrSourceType: {
    BTH: {
      Id   : '7',
      Title: 'Περίπτερο',
      Type : 'BTH',
      Icon : 'mdi-storefront'
    },
    HRM: {
      Id   : '8',
      Title: 'Δωμάτιο',
      Type : 'HRM',
      Icon : 'mdi-alpha-h-box'
    },
    SNB: {
      Id   : '9',
      Title: 'Ξαπλώστρα',
      Type : 'SNB',
      Icon : 'mdi-umbrella-beach'
    },
    TBL: {
      Id   : '6',
      Title: 'Τραπέζι',
      Type : 'TBL',
      Icon : 'mdi-table-chair'
    }
  },

  Button: {
    All     : 'Όλα',
    Save    : 'Αποθήκευση',
    Update  : 'Ενημέρωση',
    Confirm : 'Επιβεβαίωση',
    Copy    : 'Αντιγραφή',
    View    : 'Προβολή',
    ViewAll : 'Προβολή Όλων',
    New     : 'Δημιουργία',
    Create  : 'Δημιουργία',
    Continue: 'Συνέχεια',
    Edit    : 'Επεξεργασία',
    Comments: 'Σχόλια',
    Export  : 'Εξαγωγή',
    Import  : 'Εισαγωγή',
    Delete  : 'Διαγραφή',
    Remove  : 'Διαγραφή',
    Reset   : 'Επαναφορά',
    Login   : 'Σύνδεση',
    Logout  : 'Αποσύνδεση',
    Cancel  : 'Ακύρωση',
    Change  : 'Αλλαγή',
    Close   : 'Κλείσιμο',
    Send    : 'Αποστολή',
    Yes     : 'Ναι',
    No      : 'Όχι',
    Ok      : 'Οκ',
    Next    : 'Επόμενο',
    Prev    : 'Προηγούμενο',
    Toggle  : {
      Active  : 'Ενεργό',
      Inactive: 'Ανενεργό',

      ActiveF   : 'Ενεργή',
      InactiveF : 'Ανενεργή',
      ActiveFP  : 'Ενεργές',
      InactiveFP: 'Ανενεργές',

      ActiveM   : 'Ενεργός',
      InactiveM : 'Ανενεργός',
      ActiveMP  : 'Ενεργοί',
      InactiveMP: 'Ανενεργοί'
    }
  },

  Error: {
    Generic    : 'Υπήρξε κάποιο πρόβλημα. Παρακαλώ προσπαθήστε ξανά.',
    Staging    : 'Προσοχή! Αυτό είναι DEMO περιβάλλον.',
    Development: 'Προσοχή! Αυτό είναι DEV περιβάλλον.'
  },

  Misc: {
    From     : 'Από',
    To       : 'Σε',
    Locale   : 'Γλώσσα',
    CreatedAt: '@:Common.Button.Create',
    UpdatedAt: '@:Common.Button.Update',
    DeletedAt: '@:Common.Button.Delete'
  },

  Marketplace: {
    Account: {
      Connected: {
        Title   : 'Επιτυχής Σύνδεση',
        Subtitle: 'Ο λογαριασμός σας συνδέθηκε επιτυχώς με το marketplace'
      }
    }
  },

  Footer: {
    Company: {
      Title: 'Εταιρία',
      Items: [
        {
          Title: 'Ποιοι Είμαστε',
          To   : { name: 'PageAbout' }
        },
        {
          Title: 'Πώς Λειτουργεί',
          To   : { name: 'PageHowItWorks' }
        },
        {
          Title: 'FAQs',
          To   : { name: 'PageFaq' }
        },
        {
          Title: 'Επικοινωνία',
          To   : { name: 'PageContact' }
        }
      ]
    },
    Stores: {
      Title: 'Καταστήματα'
    },
    Terms: {
      Title: 'Όροι'
    },
    FollowUs: {
      Title: 'Ακολουθήστε μας',
      Items: [
        {
          Title: 'Blog',
          Link : '#'
        },
        {
          Title: 'Instagram',
          Link : '#'
        },
        {
          Title: 'Facebook',
          Link : '#'
        },
        {
          Title: 'LinkedIn',
          Link : '#'
        }
      ]
    }
  }
}
