<template>
  <v-footer
    clipped-right
    dark
    padless
  >
    <v-container fluid>
      <v-row>
        <v-col
          class="pb-0"
          cols="12"
          lg="10"
          offset-lg="1"
        >
          <v-row
            class="align-center justify-center justify-space-between py-2"
            no-gutters
          >
            <v-col
              :style="`min-width: ${$vuetify.breakpoint.xsOnly ? 68 : 75}px; min-height:20px;`"
              class="text-no-select"
              cols="auto"
              @click="showVersion = !showVersion"
            >
              <v-slide-x-transition>
                <span
                  v-show="showVersion"
                  class="caption ml-2 grey--text text--darken-2"
                >v{{ $dataStore.App.Version }}</span>
              </v-slide-x-transition>
            </v-col>

            <v-col cols="auto">
              <div class="pa-1 mt-1">
                &copy; {{ new Date().getFullYear() }} - Powered by <strong><a
                  href="https://otodev.gr"
                  target="_blank"
                >OTODEV</a></strong>
              </div>
            </v-col>

            <v-col cols="auto">
              <v-btn
                v-if="AppConfig.Theme.ToggleDarkButton"
                class="mr-2"
                depressed
                fab
                x-small
                @click="toggleThemeDark"
              >
                <v-icon>mdi mdi-invert-colors</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>

export default {
  name      : 'AppFooter',
  components: {},
  directives: {},
  mixins    : [],
  props     : {},
  dataStore : {},
  data () {
    return {
      showVersion: false
    }
  },
  computed: {},
  watch   : {},
  beforeCreate () {
  },
  created () {
    this.$vuetify.theme.dark = this.$localStorage.get('dark', this.AppConfig?.Theme?.Dark || this.$vuetify.theme.dark)
  },
  beforeMount () {
  },
  mounted () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    toggleThemeDark () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      this.$localStorage.set('dark', this.$vuetify.theme.dark)
    }
  }
}
</script>

<style scoped>
a:link {
  color           : #FFFFFF;
  font-size       : 14px;
  text-decoration : none;
}

a:visited {
  color           : #FFFFFF;
  font-size       : 14px;
  text-decoration : none;
}

a:hover {
  color           : #FFFFFF;
  font-size       : 14px;
  text-decoration : none;
}

a:active {
  color           : #FFFFFF;
  font-size       : 14px;
  text-decoration : none;
}
</style>
