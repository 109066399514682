export const App = {
  get Version () {
    return process.env.VUE_APP_VERSION
  },

  Theme: {
    get Default () {
      return process.env.VUE_APP_DEFAULT_THEME
    },
    Active: process.env.VUE_APP_ACTIVE_THEME
  },

  Config: {
    Toolbar: {
      AppToolbarExtended: false
    },
    Theme: {
      Dark            : false,
      ToggleDarkButton: true
    }
  }
}
