/* IMPORTS START */
import ApiResponseEnum from '@/api/enums/ApiResponseEnum'
import Http            from '@/lib/data/api/Http'
import InstantWin      from '@/api/endpoint/InstantWin'

/* IMPORTS END */

export const API = {
  get RESTRequest () {
    return Http.Request
  },
  get RESTRequestAll () {
    return Http.RequestAll
  },
  get RESTClient () {
    return Http
  },

  get Resource () {
    return {
      /* RESOURCES */
      InstantWin: InstantWin
    }
  },

  get Endpoint () {
    return {
      /* ENDPOINTS */
      InstantWin: {
        Spin: {
          Path  : 'spin',
          Method: Http.Method.Post
        }
      }
    }
  },

  responseData     : (response) => response?.data || null,
  responseErrors   : (response) => response?.data?.error || null,
  isResponseSuccess: (response) =>
    response?.status === ApiResponseEnum.SUCCESS &&
    response?.data?.status === 'success' &&
    API.responseData(response) && !API.responseErrors(response)
}

export { default as Http } from '@/lib/data/api/Http'
export default API
