export default {
  Home: {
    Title: 'Αρχική',
    Icon : 'mdi-home',
    Color: 'primary',
    Meta : {
      Title: '@:Route.Home.Title',
      Desc : '',
      Img  : ''
    }
  }
}
