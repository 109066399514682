import Vue       from 'vue'
import VueI18n   from 'vue-i18n'
import dayjs     from 'dayjs'
import el        from './locale/el/Index'
import en        from './locale/en/Index'
import 'dayjs/locale/el'
import 'dayjs/locale/en'
import DataStore from '@/lib/data/dataStore/DataStore'

Vue.use(VueI18n)

export const languages = [
  {
    active    : false,
    visible   : false,
    default   : false,
    name      : 'Ελληνικά',
    short_name: 'GR',
    locale    : 'el',
    code      : 'el-GR',
    code2     : 'el_GR',
    icon      : '/img/flags/1x1/gr.svg'
  },
  {
    active    : false,
    visible   : false,
    default   : false,
    name      : 'English',
    short_name: 'EN',
    locale    : 'en',
    code      : 'en-US',
    code2     : 'en_US',
    icon      : '/img/flags/1x1/gb.svg'
  }
]

const messages = {
  el: el,
  en: en
}

export const i18n = new VueI18n({
  locale               : process.env.VUE_APP_I18N_LOCALE || 'el',
  defaultLocale        : process.env.VUE_APP_I18N_LOCALE || 'el',
  fallbackLocale       : process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentTranslationWarn: process.env.NODE_ENV === 'production',
  messages
})
i18n.languages = languages

export const initI18nLanguages = () => {
  for (let i = 0; i < languages.length; i++) {
    const lang = languages[i]

    lang.default = false
    lang.active = false
    lang.visible = false

    if (lang.locale === DataStore?.App?.Config?.Marketplace?.DefaultLocale) {
      i18n.locale = lang.locale
      i18n.defaultLocale = lang.locale
      lang.default = true
    }
    if (DataStore?.App?.Config?.Marketplace?.ActiveLocales?.includes(lang.locale)) {
      lang.active = true
      lang.visible = true
    }
  }

  i18n.languages = languages
}

export const getI18nDefaultLanguage = () => {
  return languages?.find(l => l.default) || languages[0]
}

export const setI18nLanguage = (locale) => {
  const lang = languages.find(l => l.locale === locale) || getI18nDefaultLanguage()

  i18n.locale = lang.locale
  i18n.code = lang.code
  i18n.code2 = lang.code2
  i18n.name = lang.name
  i18n.short_name = lang.short_name
  i18n.active = lang.active
  if (dayjs) dayjs.locale(lang.locale)
  if (window.Vue) window.Vue.$vuetify.lang.current = lang.locale
  document.querySelector('html').setAttribute('lang', lang.locale)
  return lang
}

// Hot Reloading Language Updates
if (module.hot) {
  module.hot.accept(
    [
      './locale/el/Index',
      './locale/en/Index'
    ], function () {
      i18n.setLocaleMessage('el', require('./locale/el/Index').default)
      i18n.setLocaleMessage('en', require('./locale/en/Index').default)
    })
}

export default i18n
