import { API } from '@/api/Api'

export default {
  async Spin (code, config = {}) {
    const Config = { ...config }
    return await API.RESTRequest(API.Endpoint.InstantWin.Spin, {
      code: code
    }, Config)
  }
}
