import * as _colors     from 'vuetify/es5/util/colors'
import * as _colorUtils from 'vuetify/es5/util/colorUtils'

/**
 * Get the contrasting color for any hex color
 *
 * @param color
 * @param YIQRatio
 * @return {String} The contrasting color (black or white)
 */
export const getContrastColor = (color, YIQRatio = 128) => {
  let hexColor = getCssColor(color)

  // If a leading # is provided, remove it
  if (hexColor.slice(0, 1) === '#') hexColor = hexColor.slice(1)

  // If a three-character hexCode, make six-character
  if (hexColor.length === 3) hexColor = hexColor.split('').map(hex => hex + hex).join('')

  // Convert to RGB value
  const r = parseInt(hexColor.substr(0, 2), 16)
  const g = parseInt(hexColor.substr(2, 2), 16)
  const b = parseInt(hexColor.substr(4, 2), 16)

  // Get YIQ ratio
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000

  // Check contrast
  return (yiq >= YIQRatio) ? 'black' : 'white'
}

export const getCssColor = (color, currentTheme = (window?.Vue?.$vuetify?.theme?.dark || window?.Vue?.$vuetify?.theme?.isDark) ? window?.Vue?.$vuetify?.theme?.themes?.dark : window?.Vue?.$vuetify?.theme?.themes?.light) => {
  return !(0, _colorUtils.isCssColor)(color) ? (0, _colorUtils.classToHex)(color, _colors.default, currentTheme) : color
}

/**
 * Generates a random hexadecimal color code.
 *
 * Use Math.random to generate a random 24-bit(6x4bits) hexadecimal number.
 * Use bit shifting and then convert it to an hexadecimal String using toString(16).
 *
 * @example randomHexColorCode(); // "#e34155"
 *
 * @returns {string}
 */
export const randomHexColorCode = () => {
  const n = (Math.random() * 0xfffff * 1000000).toString(16)
  return '#' + n.slice(0, 6)
}

/**
 * Generates a random RBG color code.
 *
 * @example randomRGBColorCode(); // rgb(39, 174, 96) || rgba(39, 174, 96, 255)
 *
 * @returns {string}
 */
export const randomRGBColorCode = () => {
  return hexToRGB(randomHexColorCode())
}

/**
 * Converts the values of RGB components to a color code.
 *
 * Convert given RGB parameters to hexadecimal string using bitwise left-shift operator (<<)
 * and toString(16), then String.padStart(6,'0') to get a 6-digit hexadecimal value.
 *
 * @export RGBToHex(255, 165, 1); // 'ffa501'
 *
 * @param r
 * @param g
 * @param b
 * @returns {*|string}
 * @constructor
 */
export const RGBToHex = (r, g, b) => ((r << 16) + (g << 8) + b).toString(16).padStart(6, '0')

/**
 * Converts a color code to a rgb() or rgba() string if alpha value is provided.
 *
 * Use bitwise right-shift operator and mask bits with & (and) operator to convert a hexadecimal
 * color code (with or without prefixed with #) to a string with the RGB values. If it's 3-digit
 * color code, first convert to 6-digit version. If an alpha value is provided alongside 6-digit
 * hex, give rgba() string in return.
 *
 * @example hexToRGB('#27ae60ff'); // 'rgba(39, 174, 96, 255)'
 * @example hexToRGB('27ae60'); // 'rgb(39, 174, 96)'
 * @example hexToRGB('#fff'); // 'rgb(255, 255, 255)'
 *
 * @param hex
 * @returns {string}
 */
export const hexToRGB = hex => {
  let alpha = false
  let h = hex.slice(hex.startsWith('#') ? 1 : 0)
  if (h.length === 3) {
    h = [...h].map(x => x + x).join('')
  } else if (h.length === 8) alpha = true
  h = parseInt(h, 16)
  return (
    'rgb' +
    (alpha ? 'a' : '') +
    '(' +
    (h >>> (alpha ? 24 : 16)) +
    ', ' +
    ((h & (alpha ? 0x00ff0000 : 0x00ff00)) >>> (alpha ? 16 : 8)) +
    ', ' +
    ((h & (alpha ? 0x0000ff00 : 0x0000ff)) >>> (alpha ? 8 : 0)) +
    (alpha ? `, ${ h & 0x000000ff }` : '') +
    ')'
  )
}

export const hexToRGBA = (hex, opacity = 1) => {
  let alpha = false
  let h = hex.slice(hex.startsWith('#') ? 1 : 0)
  if (h.length === 3) {
    h = [...h].map(x => x + x).join('')
  } else if (h.length === 8) alpha = true
  h = parseInt(h, 16)
  return (
    'rgba' +
    '(' +
    (h >>> (alpha ? 24 : 16)) +
    ', ' +
    ((h & (alpha ? 0x00ff0000 : 0x00ff00)) >>> (alpha ? 16 : 8)) +
    ', ' +
    ((h & (alpha ? 0x0000ff00 : 0x0000ff)) >>> (alpha ? 8 : 0)) +
    (alpha ? `, ${ h & 0x000000ff }` : `, ${ opacity }`) +
    ')'
  )
}
